import React from 'react';

const MovieCard = ({ movie, history }) => {
  const date = movie.release_date.substring(0, 4);
  return (
    <div
      className="rounded shadow-lg w-64 sm:w-84 m-8 relative bg-white"
      onClick={() => history.push(`/movie/${movie.id}`)}
    >
      <span className="absolute -top-20 -right-20 rounded-full bg-white p-4">{movie.vote_average}</span>
      <img className="w-full" src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} alt={movie.title} />
      <section className="p-4">
        <h2 className="font-bold text-l sm:text-xl mb-2">{movie.title}</h2>
        <p>{date}</p>
        <p>Language: {movie.original_language}</p>
      </section>
    </div>
  );
}

export default MovieCard;

/*

{
  "movie": {
    "popularity": 65.77,
    "vote_count": 1434,
    "video": false,
    "poster_path": "/fQ40gmFM4p03tXwMxQQKh2cCBW4.jpg",
    "id": 373571,
    "adult": false,
    "backdrop_path": "/cNt14e43I2DDW6Xd9zFhrP8eOcA.jpg",
    "original_language": "en",
    "original_title": "Godzilla: King of the Monsters",
    "genre_ids": "Array[2]",
    "title": "Godzilla: King of the Monsters",
    "vote_average": 6.1,
    "overview": "Follows the heroic efforts of the crypto-zoological agency Monarch as its members face off against a battery of god-sized monsters, including the mighty Godzilla, who collides with Mothra, Rodan, and his ultimate nemesis, the three-headed King Ghidorah. When these ancient super-species - thought to be mere myths - rise again, they all vie for supremacy, leaving humanity's very existence hanging in the balance.",
    "release_date": "2019-05-31"
  }
}

*/