import React from "react";

const RouletteModal = (props) => {
  // const []
  const fetchMoviesByGenre = e => {
    // build a list of chosenGenres
    // set the URL
    // close the modal
    // reset radio state
    e.preventDefault();
    const checked = props.genres.filter(genre => genre.checked);
    const genreIdList = checked.map(genre => genre.id).join(',');
    props.setChosenGenres(checked);
    props.setCurrentPage(0);
    props.setUrl(`${props.url}&with_genres=${genreIdList}`);
    props.onRequestClose();
  }

  const handleChanges = e => {
    props.setGenres(props.genres.map(genre => {
      if (genre.name === e.target.name) {
        return {
          ...genre,
          checked: e.target.checked
        }
      }
      return genre;
    }))
  }

  return (
    <>
      <div className="px-6 py-4">
        <h2 className="font-bold text-xl mb-2">Movie Roulette</h2>
        <p className="text-gray-700 text-base">
          Select genre
        </p>
        {props.genres.map(genre => (
          <React.Fragment key={genre.id}>
            <input
              type="checkbox"
              checked={genre.checked}
              onChange={handleChanges}
              name={genre.name}
            />
            <label>{genre.name}</label>
          </React.Fragment>
        ))}
      </div>
      <div className="px-6 py-4">
        <button onClick={fetchMoviesByGenre}>Roll</button>
      </div>
    </>
  )
}

export default RouletteModal;