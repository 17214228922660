import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRandom } from '@fortawesome/free-solid-svg-icons'
import ReactModal from 'react-modal';
import RouletteModal from "./RouletteModal";
import { axiosConfig as axios } from '../utils/axiosConfig';

import MovieCard from './MovieCard';
import './home.scss';

const Home = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [movies, setMovies] = useState([]);
  const [chosenGenres, setChosenGenres] = useState([]);
  const [url, setUrl] = useState(`/discover/movie?page=${currentPage + 1}sort_by=popularity.desc&api_key=50b33e913e8cbb5ca99210b0100c4816`)
  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const res = await axios.get(url);
        if (!currentPage) {
          setMovies([...movies, ...res.data.results]);
        } else {
          setMovies(res.data.results);
        }
        setCurrentPage(res.data.page);
      } catch (err) {
        console.log(err);
      }
    }
    fetchMovies();
  }, [url]);

  const [genres, setGenres] = useState([]);
  const [genreUrl, setGenreUrl] = useState()
  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const res = await axios.get(genreUrl);
        setGenres(res.data.genres.map(genre => ({ ...genre, checked: false })));
      } catch (err) {
        console.log(err);
      }
    }

    // only call when opening to modal, and if we haven't fetched genres already
    if (genreUrl && !genres.length) {
      fetchGenres();
    }
  }, [genreUrl])



  const fetchNextPage = e => {
    e.preventDefault();
    setUrl(`/discover/movie?page=${currentPage + 1}&sort_by=popularity.desc&api_key=50b33e913e8cbb5ca99210b0100c4816`);
  }

  const openModal = e => {
    e.preventDefault();
    ReactModal.setAppElement('#root');
    setIsOpen(true);
  }

  const handleRequestCloseFunc = () => {
    setIsOpen(false);
  }

  return (
    <>
      <section className="homepage flex flex-col items-center justify-center">
        <div className="movie-list flex flex-row flex-wrap justify-around">
          {movies.map(movie => (
            <MovieCard key={movie.id} movie={movie} history={history} />
          ))}
        </div>
      </section>
      <div className="flex justify-end w-full">
        <div className="flex flex-row w-2/4 justify-between">
          <button className="rounded-full bg-white px-3 py-4 mb-2" onClick={fetchNextPage}>Load</button>
          <button className="rounded-full bg-white px-3 py-4 mb-2" onClick={openModal}><FontAwesomeIcon icon={faRandom} /></button>
        </div>
      </div>
      <ReactModal
        className="max-w-sm bg-white rounded"
        isOpen={isOpen}
        onRequestClose={handleRequestCloseFunc}
        contentLabel="Pick a random movie by genre modal"
        onAfterOpen={() => setGenreUrl('/genre/movie/list?api_key=50b33e913e8cbb5ca99210b0100c4816&language=en-US')}
      >
        <RouletteModal
          genres={genres}
          setGenres={setGenres}
          setCurrentPage={setCurrentPage}
          setChosenGenres={setChosenGenres}
          setUrl={setUrl}
          url={url}
          onRequestClose={handleRequestCloseFunc}
        />
      </ReactModal>
    </>
  );
}

export default Home;

// TODO: Fetch movie data