import React, { useState, useEffect } from 'react';
import { axiosConfig as axios } from '../utils/axiosConfig';

const MovieDetails = ({ match }) => {
  // TODO: fetch movie by id
  // set the movie to "state"
  const [movie, setMovie] = useState({});
  useEffect(() => {
    // api call
    const fetchMovie = async () => {
      try {
        const res = await axios.get(`/movie/${match.params.id}?api_key=50b33e913e8cbb5ca99210b0100c4816`);
        setMovie(res.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchMovie();
  }, [match.params.id]);
  // side effects -> API calls, event listeners, subscriptions 
  // dependency array tells the effect when to run
  // run the effect whenever the "id" param changes

  return (
    <div className="movie-details p-16">
      <h1 className="font-bold text-l sm:text-4xl mb-4">{movie.title} ({movie.release_date && movie.release_date.substring(0, 4)})</h1>
      <img src={`https://image.tmdb.org/t/p/original${movie.backdrop_path}`} alt={movie.title} />
    </div>
  );
}

export default MovieDetails;