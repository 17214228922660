import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Home from './components/Home';
import MovieDetails from './components/MovieDetails';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/movie/:id" component={MovieDetails} />
          <Route path='/' component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

// TODO: Create Card component
// TODO: Create Details component
